// src/data/privacyPolicy.js
export const privacyPolicyText = `
POLITICA DE CONFIDENȚIALITATE
1. Introducere
Această Politică de Confidențialitate explică modul în care Centrul Republican de Diagnosticare Medicală (CRDM) colectează, utilizează, stochează și protejează datele cu caracter personal ale utilizatorilor website-ului [https://programare.crdm.md]. Prin accesarea și utilizarea acestui website, vă exprimați acordul față de prelucrarea datelor dvs. personale conform prezentei politici.
2. Datele colectate
a. Informații personale: Nume, prenume, adresă de email, număr de telefon, data nașterii și alte date de identificare furnizate de utilizator.
3. Scopurile prelucrării datelor
a. Gestionarea programărilor: Utilizarea datelor pentru administrarea și confirmarea programărilor pentru consultații și investigații medicale.
b. Comunicare: Transmiterea de notificări, confirmări, modificări sau anulări ale programărilor.
c. Îmbunătățirea serviciilor: Analiza traficului și a comportamentului utilizatorilor în scopul optimizării funcționalității website-ului.
d. Respectarea obligațiilor legale: Asigurarea conformității cu cerințele legale și reglementările în vigoare.
4. Temeiul legal al prelucrării
Prelucrarea datelor se efectuează pe baza:
a. Consimțământului explicit al utilizatorului, acordat prin completarea formularelor de programare.
b. Necesității executării unui contract (serviciul de programări online).
c. Obligațiilor legale în vigoare.
5. Destinatarii datelor
a. Personal autorizat: Datele sunt accesibile exclusiv personalului autorizat din cadrul CRDM, care are nevoie de acestea pentru prestarea serviciilor.
b. Terți: În anumite situații, datele pot fi partajate cu furnizori de servicii IT sau alți parteneri, numai în măsura strict necesară și cu respectarea normelor legale privind protecția datelor.
6. Măsuri de securitate
a. CRDM implementează măsuri tehnice și organizatorice adecvate pentru a proteja datele cu caracter personal împotriva accesului neautorizat, modificării, pierderii sau divulgării.
b. Accesul la date este restricționat la personalul autorizat și se efectuează în conformitate cu politicile interne de securitate.
7. Drepturile utilizatorilor
Utilizatorii au următoarele drepturi în legătură cu datele lor:
a. Dreptul de acces: Dreptul de a solicita informații privind datele personale colectate.
b. Dreptul de rectificare: Dreptul de a solicita corectarea datelor inexacte sau incomplete.
c. Dreptul de ștergere: Dreptul de a solicita ștergerea datelor, în condițiile prevăzute de lege.
d. Dreptul de restricționare: Dreptul de a solicita restricționarea prelucrării datelor.
Pentru exercitarea acestor drepturi, utilizatorii pot trimite o solicitare la datele de contact menționate mai jos.
8. Perioada de păstrare a datelor
Datele personale vor fi păstrate pentru perioada necesară îndeplinirii scopurilor pentru care au fost colectate și, ulterior, doar în măsura în care este necesar pentru respectarea obligațiilor legale și contractuale.
9. Modificări ale Politicii de Confidențialitate
CRDM își rezervă dreptul de a modifica această Politică de Confidențialitate. Orice modificare va fi publicată pe website, iar utilizarea ulterioară a serviciului va constitui acceptarea modificărilor.
10. Informații de contact
Pentru întrebări, clarificări sau solicitări referitoare la prelucrarea datelor cu caracter personal, vă rugăm să ne contactați la:
•\tEmail: [crdm@ms.md]
•\tTelefon: [022 888 347]
•\tAdresă: [Сonstantin Virnav 13, MD-2025, Chișinău]
Prin accesarea și utilizarea website-ului, confirmați că ați citit, ați înțeles și sunteți de acord cu prelucrarea datelor dvs. personale conform prezentei Politici de Confidențialitate.


`;