// ReservationFlow.js
import React, { Suspense, useEffect, useRef, useState } from 'react';
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Spinner,
    Text,
    useBreakpointValue,
    VStack,
    Link as ChakraLink,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
} from '@chakra-ui/react';

import { ArrowBackIcon, ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import axios from 'axios';
import io from 'socket.io-client';

import SpecialtiesSelection from './SpecialtiesSelection';
import DoctorsSelection from './DoctorsSelection';

import { termsAndConditionsText } from '../data/termsAndConditions';
import { privacyPolicyText } from '../data/privacyPolicy';

const TimeSlotSelection = React.lazy(() => import('./TimeSlotSelection'));
const Step1 = React.lazy(() => import('./Step1'));
const ConfirmationStep = React.lazy(() => import('./ConfirmationStep'));

const API_URL = process.env.REACT_APP_API_URL;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const socket = io(SOCKET_URL);

const initialState = {
    name: '',
    surname: '',
    phoneNumber: '',
    service: '',
    date: '',
    time: '',
    IDNP: '',
    noIDNP: false,
    email: '',
    noEmail: false,
    gender: '',
    age: '',
    agreedToTerms: false,
};

function ReservationFlow() {
    const navigate = useNavigate();
    const location = useLocation();

    const isMobile = useBreakpointValue({ base: true, md: false });

    const [formData, setFormData] = useState(initialState);
    const [specialties, setSpecialties] = useState([]);
    const [doctorProfiles, setDoctorProfiles] = useState([]);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [appointmentDetails, setAppointmentDetails] = useState({});
    const [formValid, setFormValid] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const [submitAttempted, setSubmitAttempted] = useState(false);


    // State for modals
    const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
    const [isTermsOpen, setIsTermsOpen] = useState(false);

    const stepItems = [
        { title: 'Specialitate', description: 'Alege o Specialitate' },
        { title: 'Doctor', description: 'Selectează un Doctor' },
        { title: 'Program', description: 'Timpul Dorit' },
        { title: 'Date', description: 'Datele Personale' },
        { title: 'Final', description: 'Confirmarea Detaliilor' },
    ];

    const stepOrder = [
        '/reservation/selectSpecialty',
        '/reservation/selectDoctor',
        '/reservation/selectTimeSlot',
        '/reservation/fillForm',
        '/reservation/confirmAppointment',
    ];

    const currentStepIndex = stepOrder.indexOf(location.pathname);
    const prevPathRef = useRef(location.pathname);

    useEffect(() => {
        const fetchSpecialties = async () => {
            try {
                const response = await axios.get(`${API_URL}/specialties`);
                const sortedSpecialties = response.data
                    .filter((s) => s.is_active)
                    .sort((a, b) => a.name.localeCompare(b.name));
                setSpecialties(sortedSpecialties);
            } catch (error) {
                console.error('Failed to fetch specialties:', error);
            }
        };
        fetchSpecialties();
    }, []);

    const isHandlingNavigation = useRef(false);

    useEffect(() => {
        const handleNavigation = async () => {
            if (isHandlingNavigation.current) return;
            isHandlingNavigation.current = true;
            const path = location.pathname;
            const prevPath = prevPathRef.current;
            if (
                prevPath === '/reservation/fillForm' &&
                path === '/reservation/selectTimeSlot' &&
                selectedTimeSlot
            ) {
                socket.emit('release_slot', selectedTimeSlot.id);
                setSelectedTimeSlot(null);
            }
            if (path === '/reservation/selectDoctor' && !selectedSpecialty) {
                navigate('/reservation/selectSpecialty', { replace: true });
            } else if (path === '/reservation/selectTimeSlot' && !selectedDoctor) {
                navigate('/reservation/selectDoctor', { replace: true });
            }
            prevPathRef.current = path;
            isHandlingNavigation.current = false;
        };
        handleNavigation();
    }, [location.pathname, selectedSpecialty, selectedDoctor, selectedTimeSlot, navigate]);

    const handleSelectSpecialty = async (specialtyId) => {
        setSelectedSpecialty(specialtyId);
        setSelectedDoctor(null);
        setSelectedTimeSlot(null);
        try {
            const response = await axios.get(`${API_URL}/doctor_profiles/specialty/${specialtyId}`);
            setDoctorProfiles(response.data);
        } catch (error) {
            console.error('Failed to fetch doctor profiles:', error);
            setDoctorProfiles([]);
        }
        navigate('/reservation/selectDoctor');
    };

    const handleViewProfile = async (doctorId, specialtyId) => {
        try {
            await axios.get(`${API_URL}/doctor_schedules/available/${doctorId}/${specialtyId}`);
            const doc = doctorProfiles.find((doctor) => doctor.doctor_id === doctorId);
            setSelectedDoctor(doc);
            setSelectedTimeSlot(null);
            navigate('/reservation/selectTimeSlot');
        } catch (error) {
            console.error('Error fetching timeslots:', error);
        }
    };

    const onTimeslotSelected = (ts) => {
        setSelectedTimeSlot(ts);
    };

    const handleTimeSlotContinue = () => {
        if (selectedTimeSlot) {
            socket.emit('hold_slot', selectedTimeSlot.id);
            navigate('/reservation/fillForm');
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setSubmitAttempted(true); // trigger error display in Step1
        // Check if the terms checkbox is checked
        if (!formData.agreedToTerms) {
            alert("Trebuie să acceptați Termenii și Politica de Confidențialitate pentru a continua.");
            return;
        }
        if (!formValid) {
            alert('Verificați formularul pentru erori.');
            return;
        }
        if (!selectedDoctor) {
            alert("Selectați un medic înainte de a continua.");
            return;
        }
        let appointmentDate = '';
        const specialty = specialties.find((s) => s.id === selectedSpecialty);
        const doctor = selectedDoctor;
        const formatTime = (timeString) => {
            const [hours, minutes] = timeString.split(':');
            return `${hours}:${minutes}`;
        };
        if (selectedTimeSlot && selectedTimeSlot.date) {
            const date = new Date(selectedTimeSlot.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            appointmentDate = utcDate.toISOString().split('T')[0];
        }
        const details = {
            doctorId: doctor.doctor_id,
            specialtyId: specialty.id,
            specialtyName: specialty.name,
            doctorName: doctor.name,
            doctorSurname: doctor.surname,
            appointmentDate: appointmentDate,
            date: appointmentDate,
            startTime: formatTime(selectedTimeSlot.start_time),
            endTime: formatTime(selectedTimeSlot.end_time),
            patientName: formData.name,
            patientSurname: formData.surname,
            patientPhone: formData.phoneNumber,
            patientEmail: formData.noEmail ? null : formData.email,
            IDNP: formData.noIDNP ? null : formData.IDNP,
            gender: formData.gender,
            age: formData.age,
            slotId: selectedTimeSlot.id,
            formData: formData,
        };
        setAppointmentDetails(details);
        navigate('/reservation/confirmAppointment');
    };

    const handleBackClick = () => {
        if (currentStepIndex <= 0) {
            navigate('/reservation/selectSpecialty', { replace: true });
        } else {
            const previousStep = stepOrder[currentStepIndex - 1];
            if (location.pathname === '/reservation/fillForm' && selectedTimeSlot) {
                socket.emit('release_slot', selectedTimeSlot.id);
                setSelectedTimeSlot(null);
            }
            navigate(previousStep);
        }
    };

    const renderHeading = () => {
        switch (location.pathname) {
            case '/reservation/selectSpecialty':
                return 'Selectează Specialitatea';
            case '/reservation/selectDoctor':
                return 'Selectează Medicul';
            case '/reservation/selectTimeSlot':
                return 'Selectează Data și Ora';
            case '/reservation/fillForm':
                return 'Completează Formularul';
            case '/reservation/confirmAppointment':
                return 'Pasul Final';
            default:
                return '';
        }
    };

    const isStepCompleted = (stepIndex) => {
        if (stepIndex < currentStepIndex) return true;
        const isLastStep = stepIndex === stepItems.length - 1;
        return isLastStep && isConfirmed;
    };

    const renderStepper = () => {
        const stepWidth = isMobile ? '64px' : '160px';
        const iconSize = isMobile ? '24px' : '38px';
        const fontSize = isMobile ? 'xs' : 'sm';
        return (
            <Box width="100%" px={{ base: 4, md: 8 }} py={6}>
                <Flex width="100%" justify="space-between" align="center" position="relative" mx="auto" maxW="1200px">
                    <Box
                        position="absolute"
                        top="50%"
                        left={`calc(${iconSize} / 2)`}
                        right={`calc(${iconSize} / 2)`}
                        height="2px"
                        bg="gray.200"
                        transform="translateY(-50%)"
                        zIndex={0}
                    />
                    <Box
                        position="absolute"
                        top="50%"
                        left={`calc(${iconSize} / 2)`}
                        width={
                            isConfirmed
                                ? '100%'
                                : currentStepIndex > 0
                                    ? `calc((100% - ${iconSize}) * ${currentStepIndex / (stepItems.length - 1)})`
                                    : '0%'
                        }
                        height="2px"
                        bg="blue.500"
                        transform="translateY(-50%)"
                        transition="width 0.3s ease-in-out"
                        zIndex={0}
                    />
                    {stepItems.map((step, index) => {
                        const completed = isStepCompleted(index);
                        const active = index === currentStepIndex && !isConfirmed;
                        const circleColor = completed ? 'green.500' : active ? 'blue.500' : 'gray.200';
                        return (
                            <Flex key={index} direction="column" align="center" flex="1" minW={stepWidth} position="relative" zIndex={1}>
                                <Box
                                    width={iconSize}
                                    height={iconSize}
                                    borderRadius="full"
                                    bg={circleColor}
                                    color="white"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    transition="all 0.2s"
                                    position="relative"
                                    {...(active && {
                                        _before: {
                                            content: '""',
                                            position: 'absolute',
                                            top: '-4px',
                                            left: '-4px',
                                            right: '-4px',
                                            bottom: '-4px',
                                            borderRadius: 'full',
                                            border: '2px solid',
                                            borderColor: 'blue.500',
                                        },
                                    })}
                                >
                                    {completed ? (
                                        <CheckIcon boxSize={isMobile ? 3 : 4} />
                                    ) : (
                                        <Text fontSize={isMobile ? 'xs' : 'sm'} fontWeight="bold">
                                            {index + 1}
                                        </Text>
                                    )}
                                </Box>
                                {!isMobile && (
                                    <VStack spacing={0} mt={2} opacity={!completed && !active ? 0.5 : 1} transition="opacity 0.2s">
                                        <Text fontSize={fontSize} fontWeight="bold" color={active ? 'blue.500' : 'gray.700'} textAlign="center">
                                            {step.title}
                                        </Text>
                                        <Text fontSize="xs" color="gray.500" textAlign="center" display={{ base: 'none', lg: 'block' }}>
                                            {step.description}
                                        </Text>
                                    </VStack>
                                )}
                            </Flex>
                        );
                    })}
                </Flex>
            </Box>
        );
    };

    // Footer Component with modal triggers
    const Footer = () => (
        <Box as="footer" width="100%" py={10} px={6} bg="gray.100" mt="50px">
            <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
                <Text fontSize="sm" color="gray.600">
                    © 2024 - {new Date().getFullYear()} {' | '} Secția TIC - Toate drepturile rezervate.
                </Text>
                <Text fontSize="sm" color="gray.600" mt={{ base: 2, md: 0 }}>
                    <ChakraLink onClick={() => setIsPrivacyOpen(true)} color="blue.500" cursor="pointer">
                        Politica de confidențialitate
                    </ChakraLink>
                    {' | '}
                    <ChakraLink onClick={() => setIsTermsOpen(true)} color="blue.500" cursor="pointer">
                        Termeni și Condiții
                    </ChakraLink>
                </Text>
            </Flex>
        </Box>
    );

    // Privacy Policy Modal
    const PrivacyModal = () => (
        <Modal isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} size="xl" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Politica de confidențialitate</ModalHeader>
                <ModalCloseButton />
                <ModalBody maxH="70vh" overflowY="auto">
                    <Text whiteSpace="pre-wrap">{privacyPolicyText}</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    );

    // Terms and Conditions Modal
    const TermsModal = () => (
        <Modal isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)} size="xl" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Termeni și Condiții</ModalHeader>
                <ModalCloseButton />
                <ModalBody maxH="70vh" overflowY="auto">
                    <Text whiteSpace="pre-wrap">{termsAndConditionsText}</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    );

    return (
        // Outer Flex container with minHeight set to 100vh and flexDirection column
        <Flex
            direction="column"
            minH="100vh"
            alignItems="center"
            justifyContent="center"
            width="100%"
        >
            <Box width="100%" maxW="1200px" bg="white">
                {renderStepper()}
            </Box>
            <Divider />
            <VStack width="100%" spacing={4} flex="1">
                <Grid templateColumns="repeat(3, 1fr)" alignItems="center" width="100%" px={4} py={4}>
                    <GridItem colSpan={1}>
                        {currentStepIndex > 0 && !isConfirmed && (
                            <Button leftIcon={<ArrowBackIcon />} size="sm" variant="outline" onClick={handleBackClick} ml={{ base: 0, md: '25%' }}>
                                Înapoi
                            </Button>
                        )}
                    </GridItem>
                    <GridItem colSpan={1} textAlign="center">
                        <Heading size={{ base: 'md', md: 'lg' }}>{renderHeading()}</Heading>
                    </GridItem>
                    <GridItem colSpan={1} visibility={currentStepIndex === 0 ? 'hidden' : 'visible'}>
                        <Button size="sm" visibility="hidden">Placeholder</Button>
                    </GridItem>
                </Grid>
                <Suspense fallback={<Spinner />}>
                    <Routes>
                        <Route path="selectSpecialty" element={<SpecialtiesSelection specialties={specialties} onSelect={handleSelectSpecialty} />} />
                        <Route path="selectDoctor" element={<DoctorsSelection specialties={specialties} selectedSpecialty={selectedSpecialty} doctorProfiles={doctorProfiles} onViewProfile={handleViewProfile} />} />
                        <Route path="selectTimeSlot" element={<TimeSlotSelection doctorId={selectedDoctor?.doctor_id} specialtyId={selectedSpecialty} onTimeslotSelected={onTimeslotSelected} selectedTimeSlot={selectedTimeSlot} />} />
                        <Route path="fillForm" element={
                            <>
                                <Step1 formData={formData} setFormData={setFormData} setFormValid={setFormValid} submitAttempted={submitAttempted} setSubmitAttempted={setSubmitAttempted} />
                                <Button rightIcon={<ArrowForwardIcon size={24} />} mt="5" size="lg" variant="solid" colorScheme="blue" onClick={handleFormSubmit}>
                                    Pasul Final
                                </Button>
                            </>
                        } />
                        <Route path="confirmAppointment" element={<ConfirmationStep appointmentDetails={appointmentDetails} onEdit={() => navigate('/reservation/fillForm')} onConfirm={() => setIsConfirmed(true)} />} />
                    </Routes>
                </Suspense>
                {location.pathname === '/reservation/selectTimeSlot' && selectedTimeSlot && !isConfirmed && (
                    <Button mt="5" size="lg" variant="solid" colorScheme="blue" onClick={handleTimeSlotContinue}>
                        Continuă
                    </Button>
                )}
            </VStack>

            <Footer />
            <PrivacyModal />
            <TermsModal />
        </Flex>
    );
}

export default ReservationFlow;
