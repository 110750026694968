//navbar.js

import {
    Box, Flex,
    Avatar, HStack,
    Button, Link,
    Menu, MenuButton,
    Stack, IconButton,
    useDisclosure,
    useColorModeValue
} from '@chakra-ui/react';

import logoImage from '../assets/logo_crdm.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';


const navLinks = [
    { name: 'Despre Noi', path: 'https://crdm.md/pages/scurt_istoric.html' },
    { name: 'Contacte', path: 'https://crdm.md/pages/contacte.html' }
];

export default function Navbar() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const menuProps = {
        bg: useColorModeValue('gray.200', 'gray.700'),
        color: useColorModeValue('blue.500', 'blue.200')
    };


    return (
        <Box px={4}
             boxShadow="sm"
             width="100%"
             border='1px'
             borderColor='gray.200'>
            <Flex h={16} alignItems="center" justifyContent="space-between" maxW={900} mx="auto">
                <IconButton
                    size="sm"
                    icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
                    aria-label="Open Menu"
                    display={['inherit', 'inherit', 'none']}
                    onClick={isOpen ? onClose : onOpen}
                />
                <HStack spacing={8} alignItems="center">
                    <Avatar
                        href="https://programare.crdm.md/"
                        as={Link}
                        size="md"
                        showBorder={true}
                        borderColor="blue.400"
                        rounded="full"
                        src={logoImage}
                    />
                    <HStack as="nav" spacing={1} display={{ base: 'none', md: 'flex' }} alignItems="center">
                        {navLinks.map((link, index) => (
                            <NavLink key={index} {...link} onClose={onClose} />
                        ))}
                        {/* Dropdown Menu */}
                        <Menu autoSelect={false} isLazy>
                            {({ isOpen, onClose }) => (
                                <>
                                    <MenuButton
                                        as={Button}
                                        variant="ghost"
                                        size="sm"
                                        px={3}
                                        py={1}
                                        lineHeight="inherit"
                                        fontSize="1em"
                                        fontWeight="normal"
                                        rounded="md"
                                        height="auto"
                                        _hover={{ color: 'blue.400', bg: menuProps.bg }}
                                    >

                                    </MenuButton>

                                </>
                            )}
                        </Menu>
                    </HStack>
                </HStack>


            </Flex>

            {/* Mobile Screen Links */}
            {isOpen ? (
                <Box pb={4} display={['inherit', 'inherit', 'none']}>
                    <Stack as="nav" spacing={2}>
                        {navLinks.map((link, index) => (
                            <NavLink key={index} {...link} onClose={onClose} />
                        ))}
                    </Stack>
                </Box>
            ) : null}
        </Box>
    );
}

// NavLink Component
interface NavLinkProps {
    name: string;
    path: string;
    onClose: () => void;
}

const NavLink = ({ name, path, onClose }: NavLinkProps) => {
    const link = {
        bg: useColorModeValue('gray.200', 'gray.700'),
        color: useColorModeValue('blue.500', 'blue.200')
    };

    return (
        <Link
            href={path}
            px={3}
            py={1}
            lineHeight="inherit"
            rounded="md"
            _hover={{
                textDecoration: 'none',
                bg: link.bg,
                color: link.color
            }}
            onClick={() => onClose()}
        >
            {name}
        </Link>
    );
};



