import React, {
    useEffect,
    useState,
    useMemo,
    useCallback,
    Suspense,
    useRef,
} from 'react';
import {
    VStack,
    Divider,
    Flex,
    Button,
    SimpleGrid,
    Box,
    Heading,
    Text,
    Image,
    Card,
    CardBody,
    AspectRatio,
    Input,
    InputLeftElement,
    InputGroup,
    Grid,
    GridItem,
    useColorModeValue,
    Badge,
    useBreakpointValue,
    Spinner,
} from '@chakra-ui/react';
import {
    SearchIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    TimeIcon,
    ArrowForwardIcon,
} from '@chakra-ui/icons';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import { format } from 'date-fns';
import { Steps } from 'primereact/steps';
import io from 'socket.io-client';
import LazyLoad from 'react-lazyload';

const TimeSlotSelection = React.lazy(() => import('./TimeSlotSelection'));
const Step1 = React.lazy(() => import('./Step1'));
const ConfirmationStep = React.lazy(() => import('./ConfirmationStep'));

const API_URL = process.env.REACT_APP_API_URL;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const socket = io(SOCKET_URL);

function Step2() {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        phoneNumber: '',
        service: '',
        date: '',
        time: '',
        IDNP: '',
        noIDNP: false,
        email: '',
        noEmail: false,
        gender: '',
        age: '',
    });

    const [specialties, setSpecialties] = useState([]);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [doctorProfiles, setDoctorProfiles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [currentStep, setCurrentStep] = useState('selectSpecialty');
    const [selectedDoctor, setSelectedDoctor] = useState({});
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [appointmentDetails, setAppointmentDetails] = useState({});

    const [formValid, setFormValid] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    const handleResize = useCallback(() => {
        setIsMobileView(window.innerWidth < 768);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const handleTimeslotSelected = useCallback(
        (timeslot) => {
            setSelectedTimeSlot(timeslot);
        },
        [setSelectedTimeSlot]
    );

    const handleEditClick = useCallback(() => {
        setCurrentStep('fillForm');
    }, [setCurrentStep]);

    const gridColumns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.7 },
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.7 },
        },
    };

    const stepItems = [
        { label: 'Alege o Specialitate' },
        { label: 'Selectează un Doctor ' },
        { label: 'Timpul Dorit' },
        { label: 'Datele Personale' },
        { label: 'Confirmarea Detaliilor' },
    ];

    const getCurrentStepIndex = () => {
        switch (currentStep) {
            case 'selectSpecialty':
                return 0;
            case 'selectDoctor':
                return 1;
            case 'selectTimeSlot':
                return 2;
            case 'fillForm':
                return 3;
            case 'confirmAppointment':
                return 4;
            default:
                return 0;
        }
    };

    const renderStepLabels = () => {
        if (isMobileView) {
            const currentStepIndex = getCurrentStepIndex();
            return (
                <Box textAlign="center" mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">
                        {currentStepIndex + 1}. {stepItems[currentStepIndex].label}
                    </Text>
                </Box>
            );
        } else {
            return (
                <Steps
                    model={stepItems}
                    activeIndex={getCurrentStepIndex()}
                    style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}
                />
            );
        }
    };

    useEffect(() => {
        const fetchSpecialties = async () => {
            try {
                const response = await axios.get(`${API_URL}/specialties`);
                // Sort the specialties alphabetically by name
                const sortedSpecialties = response.data
                    .filter((s) => s.is_active)
                    .sort((a, b) => a.name.localeCompare(b.name));
                setSpecialties(sortedSpecialties);
            } catch (error) {
                console.error('Failed to fetch specialties:', error);
            }
        };
        fetchSpecialties();
    }, []);

    const handleSelectSpecialty = useCallback(
        async (specialtyId) => {
            setSelectedSpecialty(specialtyId);
            try {
                const response = await axios.get(
                    `${API_URL}/doctor_profiles/specialty/${specialtyId}`
                );
                setDoctorProfiles(response.data);
            } catch (error) {
                console.error('Failed to fetch doctor profiles:', error);
                setDoctorProfiles([]);
            }
            setCurrentStep('selectDoctor');
        },
        [setSelectedSpecialty, setDoctorProfiles, setCurrentStep]
    );

    const handleSearchChange = useCallback((event) => {
        setSearchTerm(event.target.value);
    }, []);

    const filteredSpecialties = useMemo(() => {
        return specialties.filter((specialty) =>
            specialty.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [specialties, searchTerm]);

    const handleViewProfile = useCallback(
        (doctorId, specialtyId) => {
            setSelectedDoctor(
                doctorProfiles.find((doctor) => doctor.id === doctorId)
            );
            setSelectedSpecialty(specialtyId);
            setCurrentStep('selectTimeSlot');
        },
        [doctorProfiles, setSelectedDoctor, setSelectedSpecialty, setCurrentStep]
    );

    const handleBackClick = useCallback(() => {
        if (currentStep === 'fillForm' && selectedTimeSlot) {
            socket.emit('release_slot', selectedTimeSlot.id);
            setSelectedTimeSlot(null);
        }
        switch (currentStep) {
            case 'selectDoctor':
                setCurrentStep('selectSpecialty');
                setSelectedSpecialty(null);
                break;
            case 'selectTimeSlot':
                setCurrentStep('selectDoctor');
                break;
            case 'fillForm':
                setCurrentStep('selectTimeSlot');
                break;
            case 'confirmAppointment':
                setCurrentStep('fillForm');
                break;
            default:
                break;
        }
    }, [currentStep, selectedTimeSlot]);

    const renderHeading = () => {
        switch (currentStep) {
            case 'selectSpecialty':
                return 'Selectează Specialitatea';
            case 'selectDoctor':
                return 'Selectează Medicul';
            case 'selectTimeSlot':
                return 'Selectează Data și Ora';
            case 'fillForm':
                return 'Completează Formularul';
            case 'confirmAppointment':
                return 'Pasul Final';
            default:
                return 'Selectează Specialitatea';
        }
    };

    const handleTimeSlotSelection = useCallback(
        (timeslot) => {
            if (selectedTimeSlot) {
                socket.emit('release_slot', selectedTimeSlot.id);
            }
            socket.emit('hold_slot', timeslot.id);
            setSelectedTimeSlot({
                ...timeslot,
                start_time: timeslot.start_time,
                end_time: timeslot.end_time,
            });
            setCurrentStep('fillForm');
        },
        [selectedTimeSlot, setSelectedTimeSlot, setCurrentStep]
    );

    const handleConfirmAppointment = (reservationDetails) => {
        socket.emit('confirm_reservation', reservationDetails);

        socket.on('reservation_confirmed', (response) => {
            alert('Your appointment on ${response.result.date} at ${response.result.time} is confirmed.');
        });

        socket.on('error', (error) => {
            alert('Error: ${error.message}');
        });
    };

    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        return `${hours}:${minutes}`;
    };

    const handleFormSubmit = useCallback(
        (event) => {
            event.preventDefault();
            if (!formValid) {
                alert(
                    'Vă rugăm să verificați formularul pentru erori. Asigurați-vă că toate câmpurile sunt completate corect.'
                );
                return;
            }

            let appointmentDate = '';
            let appointmentTime = '';

            const specialty = specialties.find((s) => s.id === selectedSpecialty);
            const doctor = selectedDoctor;
            if (selectedTimeSlot && selectedTimeSlot.date) {
                const date = new Date(selectedTimeSlot.date);
                const utcDate = new Date(
                    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
                );
                appointmentDate = format(utcDate, 'yyyy-MM-dd');
            }

            const appointmentDetails = {
                doctorId: doctor.id,
                specialtyId: specialty.id,
                specialtyName: specialty.name,
                doctorName: doctor.name,
                doctorSurname: doctor.surname,
                appointmentDate: appointmentDate,
                appointmentTime: appointmentTime,
                date: appointmentDate,
                startTime: formatTime(selectedTimeSlot.start_time),
                endTime: formatTime(selectedTimeSlot.end_time),
                patientName: formData.name,
                patientSurname: formData.surname,
                patientPhone: formData.phoneNumber,
                patientEmail: formData.noEmail ? null : formData.email,
                IDNP: formData.noIDNP ? null : formData.IDNP,
                gender: formData.gender,
                age: formData.age,
                slotId: selectedTimeSlot.id,
                formData: formData,
            };
            setAppointmentDetails(appointmentDetails);
            setCurrentStep('confirmAppointment');
        },
        [
            formValid,
            specialties,
            selectedSpecialty,
            selectedDoctor,
            selectedTimeSlot,
            formData,
            setAppointmentDetails,
            setCurrentStep,
        ]
    );

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 'selectSpecialty':
                return renderSpecialtiesGrid();
            case 'selectDoctor':
                return doctorProfiles.length === 0 ? (
                    <Box p={2}>
                        La moment nu există specialiști pentru această specialitate.
                    </Box>
                ) : (
                    renderDoctorProfiles()
                );
            case 'selectTimeSlot':
                return selectedDoctor && selectedSpecialty ? (
                    <Suspense fallback={<Spinner />}>
                        <TimeSlotSelection
                            doctorId={selectedDoctor.id}
                            specialtyId={selectedSpecialty}
                            onTimeslotSelected={handleTimeslotSelected}
                        />
                    </Suspense>
                ) : (
                    <Text>
                        Vă rugăm să selectați un medic și o specialitate pentru a vizualiza
                        intervalele orare disponibile.
                    </Text>
                );
            case 'fillForm':
                return (
                    <Suspense fallback={<Spinner />}>
                        <Step1
                            formData={formData}
                            setFormData={setFormData}
                            setFormValid={setFormValid}
                        />
                    </Suspense>
                );
            case 'confirmAppointment':
                return (
                    <Suspense fallback={<Spinner />}>
                        <ConfirmationStep
                            appointmentDetails={appointmentDetails}
                            onConfirm={handleConfirmAppointment}
                            onEdit={handleEditClick}
                        />
                    </Suspense>
                );
            default:
                return null;
        }
    };

    const SpecialtyCard = React.memo(({ specialty, onSelect }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        const toggleExpand = useCallback(
            (e) => {
                e.stopPropagation();
                setIsExpanded((prev) => !prev);
            },
            [setIsExpanded]
        );

        return (
            <Card
                borderRadius="lg"
                overflow="hidden"
                boxShadow="lg"
                transition="all 0.3s"
                _hover={{
                    transform: 'translateY(-5px)',
                    boxShadow: 'xl',
                }}
                onClick={() => onSelect(specialty.id)}
                cursor="pointer"
            >
                <AspectRatio ratio={16 / 9}>
                    <LazyLoad height={200} offset={100} once>
                        <Image
                            src={`${API_URL}/uploads/${specialty.image_url}`}
                            alt={specialty.name}
                            objectFit="cover"
                            w="100%"
                            h="100%"
                        />
                    </LazyLoad>
                </AspectRatio>
                <CardBody>
                    <Heading size="md" mb={2} textAlign="center">
                        {specialty.name}
                    </Heading>
                    <Text
                        fontSize="sm"
                        color="gray.600"
                        textAlign="center"
                        noOfLines={isExpanded ? undefined : 3}
                    >
                        {specialty.description}
                    </Text>
                    <Button
                        size="sm"
                        variant="ghost"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleExpand(e);
                        }}
                        mt={2}
                        width="100%"
                        rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    >
                        {isExpanded ? 'Arată mai puțin' : 'Citeşte mai mult'}
                    </Button>
                    <Box
                        mt={4}
                        textAlign="center"
                        color="blue.500"
                        fontWeight="bold"
                        fontSize="sm"
                        textTransform="uppercase"
                    >
                        Selectați specialistul
                    </Box>
                </CardBody>
            </Card>
        );
    });

    const DoctorCard = React.memo(({ doctor, onViewProfile, specialtyId }) => {
        const cardBg = useColorModeValue('white', 'gray.700');
        const borderColor = useColorModeValue('blue.100', 'blue.500');
        const textColor = useColorModeValue('gray.900', 'gray.200');

        return (
            <Box
                borderWidth="1px"
                borderRadius="lg"
                borderColor={borderColor}
                overflow="hidden"
                boxShadow="lg"
                bg={cardBg}
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-5px)', boxShadow: 'xl' }}
                height="100%"
                display="flex"
                flexDirection="column"
            >
                <Flex p={4} direction="column" flex="1">
                    <Flex mb={2}>
                        <Box flexShrink={0} mr={4}>
                            <LazyLoad height={100} offset={100} once>
                                <Image
                                    boxShadow="base"
                                    borderRadius="md"
                                    src={
                                        doctor.image_url
                                            ? `${API_URL}${doctor.image_url}`
                                            : 'default-avatar.png'
                                    }
                                    alt={doctor.name}
                                    objectFit="cover"
                                    boxSize="100px"
                                    borderColor={borderColor}
                                    borderWidth="2px"
                                />
                            </LazyLoad>
                            <Badge
                                colorScheme="blue"
                                mt={2}
                                textAlign="center"
                                width="100%"
                            >
                                {specialties.find((s) => s.id === specialtyId)?.name}
                            </Badge>
                        </Box>
                        <VStack align="start" flex="1" spacing={2}>
                            <Heading size="md">{`${doctor.name} ${doctor.surname}`}</Heading>
                            <Text color={textColor} fontSize="sm">
                                {doctor.description || 'Nu există descriere la moment :)'}
                            </Text>
                        </VStack>
                    </Flex>
                </Flex>
                <Box
                    cursor={'pointer'}
                    p={4}
                    borderTop="1px"
                    borderColor="blue.100"
                    mt="auto"
                    onClick={() => onViewProfile(doctor.id, specialtyId)}
                >
                    <Button
                        leftIcon={<TimeIcon />}
                        variant="outline"
                        borderColor={borderColor}
                        colorScheme="blue"
                        size="md"
                        onClick={() => onViewProfile(doctor.id, specialtyId)}
                        width="100%"
                    >
                        Vezi Orarul
                    </Button>
                </Box>
            </Box>
        );
    });

    const renderSpecialtiesGrid = () => (
        <AnimatePresence>
            {selectedSpecialty === null && (
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <Box width="100%" maxW="1200px" mx="auto">
                        <VStack width="100%">
                            <InputGroup width="100%" mb={4}>
                                <InputLeftElement pointerEvents="none">
                                    <SearchIcon color="gray.400" />
                                </InputLeftElement>
                                <Input
                                    type="text"
                                    placeholder="Caută specialitate"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    borderRadius="md"
                                />
                            </InputGroup>
                        </VStack>
                        <SimpleGrid
                            columns={gridColumns}
                            spacing={6}
                            width="100%"
                            px={4}
                        >
                            {filteredSpecialties.map((specialty) => (
                                <SpecialtyCard
                                    key={specialty.id}
                                    specialty={specialty}
                                    onSelect={handleSelectSpecialty}
                                />
                            ))}
                        </SimpleGrid>
                    </Box>
                </motion.div>
            )}
        </AnimatePresence>
    );

    const renderDoctorProfiles = () => (
        <AnimatePresence>
            {selectedSpecialty !== null && (
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <Box width="100%" maxW="1200px" mx="auto">
                        <Heading
                            fontSize={{ base: 'xl', md: '2xl' }}
                            mb={6}
                            textAlign="center"
                        >
                            {specialties.find((s) => s.id === selectedSpecialty)?.name}
                        </Heading>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                            {doctorProfiles.map((doctor) => (
                                <DoctorCard
                                    key={doctor.id}
                                    doctor={doctor}
                                    onViewProfile={handleViewProfile}
                                    specialtyId={selectedSpecialty}
                                />
                            ))}
                        </SimpleGrid>
                    </Box>
                </motion.div>
            )}
        </AnimatePresence>
    );

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            margin="0 auto"
            padding="0"
            direction="column"
            width="100%"
            mb="50"
        >
            <Box width="100%" maxW="1200px" px={4}>
                {renderStepLabels()}
            </Box>
            <Divider pb={3} mb={5} />
            <VStack width="100%">
                <Grid
                    templateColumns="repeat(3, 1fr)"
                    alignItems="center"
                    width="100%"
                    px={4}
                >
                    <GridItem colSpan={1}>
                        {currentStep !== 'selectSpecialty' &&
                            currentStep !== 'confirmAppointment' && (
                                <Button
                                    ml={{ base: '0', sm: '10%', md: '20%', lg: '50%' }}
                                    leftIcon={<ArrowBackIcon />}
                                    size="md"
                                    variant="outline"
                                    onClick={handleBackClick}
                                >
                                    Înapoi
                                </Button>
                            )}
                    </GridItem>
                    <GridItem colSpan={1} textAlign="center" className={'renderHeading'}>
                        <Heading size="lg">{renderHeading()}</Heading>
                    </GridItem>
                    <GridItem
                        colSpan={1}
                        visibility={currentStep === 'selectSpecialty' ? 'hidden' : 'visible'}
                    >
                        <Button size="sm" visibility="hidden">
                            Placeholder
                        </Button>
                    </GridItem>
                </Grid>
                <Divider pb={3} mb={5} />
            </VStack>
            {renderCurrentStep()}
            {currentStep === 'selectTimeSlot' && (
                <Button
                    mt="5"
                    size="lg"
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => handleTimeSlotSelection(selectedTimeSlot)}
                    isDisabled={!selectedTimeSlot}
                >
                    Continue
                </Button>
            )}
            {currentStep === 'fillForm' && (
                <Button
                    mt="5"
                    size="lg"
                    variant="solid"
                    colorScheme="blue"
                    onClick={handleFormSubmit}
                    rightIcon={<ArrowForwardIcon size={24} />}
                >
                    Confirmă Programarea
                </Button>
            )}
        </Flex>
    );
}

export default Step2;
