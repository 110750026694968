// src/data/termsAndConditions.js
export const termsAndConditionsText = `
TERMENI ȘI CONDIȚII DE UTILIZARE

1. Introducere și obiectul contractului
Acest document stabilește Termenii și Condițiile de utilizare („Termeni”) ale website-ului [https://programare.crdm.md] operat de Centrul Republican de Diagnosticare Medicală (CRDM). Prin accesarea și utilizarea acestui website, inclusiv a serviciului de programări online pentru consultații și investigații medicale, sunteți de acord să respectați acești termeni. Dacă nu sunteți de acord cu oricare dintre prevederile de mai jos, vă rugăm să vă abțineți de la utilizarea serviciului.
2. Definiții
•\tCRDM: Centrul Republican de Diagnosticare Medicală, operatorul website-ului și furnizorul serviciilor de programări online.
•\tUtilizator: Orice persoană care accesează și utilizează website-ul și serviciile acestuia.
•\tSite: Website-ul [https://programare.crdm.md] și toate paginile, aplicațiile și funcționalitățile aferente.
•\tServicii: Serviciul de programări online pentru consultații și investigații medicale pus la dispoziție de CRDM.
3. Acceptarea Termenilor
Prin accesarea și utilizarea website-ului și serviciilor sale, utilizatorul confirmă că a citit, a înțeles și acceptă în mod expres acești Termeni. Utilizarea ulterioară a serviciului va fi considerată ca fiind de acord cu orice modificări ulterioare aduse acestor termeni.
4. Modificări ale Termenilor
CRDM își rezervă dreptul de a modifica oricând acești Termeni. Orice modificare va fi publicată pe website, iar utilizarea continuă a serviciului după publicarea modificărilor constituie acceptarea noilor Termeni.
5. Accesul și utilizarea website-ului
a. Licența de utilizare: CRDM vă acordă o licență neexclusivă, netransferabilă și limitată pentru accesul și utilizarea website-ului în scopul de a vă programa la consultații sau investigații medicale.
b. Funcționarea serviciului: CRDM se angajează să asigure funcționarea platformei în condiții normale, însă nu garantează disponibilitatea continuă sau lipsa erorilor tehnice.
c. Procedura de programare: Utilizatorii pot selecta specialitatea medicală, data și ora programării conform instrucțiunilor afișate pe website. Confirmările, modificările sau anulările vor fi comunicate prin mijloacele indicate de utilizator.
6. Obligațiile utilizatorilor
a. Utilizatorul se obligă să folosească platforma numai în scopul realizării de programări medicale și să nu interfereze cu funcționarea acesteia.
b. Este interzisă utilizarea serviciului în scopuri ilegale, frauduloase sau abuzive.
c. Utilizatorul se angajează să furnizeze date personale corecte și să actualizeze orice modificare a acestora.
7. Responsabilitatea CRDM
a. CRDM nu poate fi tras la răspundere pentru erori tehnice, întreruperi temporare de serviciu sau eventualele neconcordanțe ale datelor afișate pe website.
b. În cazul problemelor legate de programări (întârzieri, neconfirmări etc.), CRDM nu își asumă răspunderea, cu excepția situațiilor de neglijență gravă sau intenționată.
c. Informațiile furnizate pe website sunt doar de natură informativă și nu constituie consultanță medicală.
8. Proprietatea intelectuală
a. Toate elementele de design, text, imagini, logo-uri, grafice, cod sursă și alte materiale publicate pe website sunt protejate de drepturile de autor și alte reglementări privind proprietatea intelectuală.
b. Orice reproducere, distribuire sau utilizare a acestor materiale fără acordul prealabil scris al CRDM este strict interzisă.
9. Legislația aplicabilă și soluționarea litigiilor
a. Acești Termeni sunt guvernați de legislația Republicii Moldova.
b. Orice litigiu apărut din sau în legătură cu acești Termeni va fi soluționat pe cale amiabilă. În cazul în care nu se ajunge la un acord, litigiul va fi înaintat instanțelor competente din Republica Moldova.
10. Informații de contact
Pentru întrebări, clarificări sau solicitări referitoare la acești Termeni, vă rugăm să ne contactați la:
•\tEmail: [crdm@ms.md]
•\tTelefon: [022 888 347]
•\tAdresă: [Сonstantin Virnav 13, MD-2025, Chișinău]
Prin utilizarea website-ului, confirmați că ați citit și ați înțeles acești Termeni și că sunteți de acord să îi respectați.

`;